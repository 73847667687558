@import "../../global.scss";

.works{
    background-color: #2B3467;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

    h1{
        color: #FCFFE7;
        font-size: 50px;
        height: 85vh;
    
        @include mobile{
            font-size: 35px;
        }
    }

    .arrow{
        height: 50px;
        position: absolute;
    

        @include mobile{
            display: none;
        }

    &.left{
        left: 100px;
        cursor: pointer;
    
    }

    &.right{
        right: 100px;
        cursor: pointer;
    }
}

    .slider{
        height: 350px;
        display: flex;
        position: absolute;
        left: 0;
        transition: all 1s ease-out;

        @include mobile{
            height: 100vh;
            flex-direction: column;
            justify-content: center
        }
    
    .container{
        width: 100vw;
        display: flex;
        align-items: center;
        justify-content: center;

        .item{
            width: 700px;
            height: 100%;
            background-color: #FCFFE7;
            border-radius: 20px;
            display: flex; 
            align-items: center;
            justify-content: center;

            @include mobile{
               width: 80%;
               height: 140px;
               margin: 15px 0;
            }

           .left{
            flex: 4;
            height: 55%;
            display: flex;
            align-items: center;
            justify-content: center;

            .leftContainer{
                width: 90%;
                height: 90%;
                display: flex;
                flex-direction: column;
                justify-content: space-between;

                @include mobile{
                    width: 100%;
                    height: 90%;
                }

                .imgContainer{
                    width: 40px;
                    height: 40px;
                    border-radius: 60%;
                    background-color: cornflowerblue;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    @include mobile{
                        width: 22px;
                        height: 22px;
                        margin: auto;
                        display: block;

                    }

                    img{
                        width: 25px;

                        @include mobile{
                            width: 14px;
                            margin-top: 4px;
                            margin-left: 4px;
                            
                        }
                    }
                }

                h1{
                    font-size: 40px;
                }

                h2{
                    font-size: 20px;
                    color: #EB455F;
                    overflow: hidden;

                    @include mobile{
                        font-size: 13;
                        text-align: center;
                    }
                }
                p{
                    
                    font-size: 13px;
                    overflow: hidden;
                    color: #EB455F;

                    @include mobile{
                        display:  none;
                    }
                }
                span{
                    font-size: 12px;
                    font-weight: 600;
                    text-decoration: underline;
                    cursor: pointer;
                }
            }

           }
           .right{
            flex: 8;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            overflow: hidden;

            img{
                height: 100%;
                width: 100%;
             
            }
           }


        }
    }
    }
}