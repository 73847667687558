@import "../../global.scss";

.portfolio{
    background-color: #BAD7E9;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: hidden;
   
    h1{
        font-size: 50px;
        margin-bottom: 40px;
        color: #FCFFE7;
        overflow: hidden;
        
        @include mobile{
            font-size: 30px;
            
        }
    }

    ul{
        margin: 30px;
        padding: 0;
        list-style: none;
        display: none;
        overflow: hidden;

        @include mobile {
            flex-wrap: wrap;
            justify-content: center;
            
        }

        li{
            font-size: 20px;
            margin-right: 50px;
            padding: 7px;
            border-radius: 10px;
            justify-content: center;
            cursor: pointer;
            overflow: hidden;

            @include mobile{
                border-radius: 10px;
            }

            &.active{
                background-color: $mainColor;
                color: white;
            }
        }
    }
    .container{
        width: 70%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
        color: #FCFFE7;
        overflow: hidden;

        @include mobile{
            width: 100%;
            border-radius: 10px;

        }
    
        .item{
            width: 220px;
            height: 160px;
            border-radius: 20px;
            border: 1px solid rgb(235, 69, 95);
            margin: 10px 20px;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: white;
            position: relative;
            transition: 0.5s;
            cursor: pointer;

            @include mobile{
                width: 130px;
                height: 100px;
                margin-bottom: 45px;
                border-radius: 10px;
            }

            h3{
                position: absolute;
                font-size: 20px;
            }

            img{
                width: 100%;
                height: 100%;
                object-fit: cover;
                z-index: 1;

                @include mobile{
                    border-radius: 10px;
                }
            }
            &:hover{
                background-color: $mainColor;
                
                img{
                opacity: 0.2;
                z-index: 0
                }
            }
        }
    
    }
}