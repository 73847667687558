 @import "../../global.scss";
 
 
 .contact{
    background-color: rgb(186, 215, 233);
    display: flex;
    color: #FCFFE7;

    @include mobile{
      flex-direction: column;
    }

    .left{
      flex: 1;
      overflow: hidden;
   
      img{
         height: 100%;
         width: 100%;
      }
   
   }
    .right{
      flex: 1;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      h2{
         font-size: 30px;
         padding-bottom: 10px;
      }

     

      form{
         width: 70%;
         height: 80%;
         display: flex;
         flex-direction: column;
         align-items: center;
         justify-content: space-around;
         border-color: #EB455F;

         
         

         input{
            width: 300px;
            height: 30px;
            font-size: 14px; 
            border-radius: 10px;
            border-color: #EB455F;
            

            @include mobile{
               width: 200px;
               height: 20px;
               border-radius: 10px;
            }
         }

         textarea{
            width: 300px;
            height: 300px;
            font-size:14px;
            border-radius: 10px;
            border-color: #EB455F;

            @include mobile{
               width: 200px;
               height: 100px;
               border-radius: 10px;
            }
         }

         button{
            width: 150px;
            height: 30px;
            color: whitesmoke;
            background-color: #EB455F;
            border: none;
            border-radius: 10px;
            font-weight: 500;
            cursor: pointer;
            margin-top: 10px;

            &:focus{
               outline: none;
            }
         }
         span{
            color: green;
         }
      }
    }
 }